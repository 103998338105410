<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label>Cargando datos, por favor espere...</ion-label>
  </div>
} @else {
  <ion-grid class="p-4">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <h2>Procedimiento para la elaboración del informe de evaluación de impacto ambiental</h2>
      </ion-col>

      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="p-col-12 mb-4 custom-align-right">
          <button
            pButton
            type="button"
            label="Añadir registro"
            icon="pi pi-plus"
            (click)="showFormDialog()"
          ></button>
        </div>

        <div class="table-container p-col-12">
          <p-table 
            #dt1
            [value]="dataImpact" 
            [paginator]="true" 
            [rows]="10"
            dataKey="id"
            [rowHover]="true"
            [globalFilterFields]="['created_at']"
            styleClass="p-datatable-striped"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="caption">
              <div
                style="gap: 1vw"
                class="flex justify-content-center align-items-center sm:flex-row"
              >
                <input
                  pInputText
                  type="text"
                  #filterGeneral
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar"
                  class="w-full"
                />
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Fecha</th>
                <th>Opciones</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td class="text-center">
                  {{ item?.created_at | date: 'dd/MM/YYYY'}}
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p-dropdown              
                      [options]="crudOptionsProducts"
                      appendTo="body"
                      optionLabel="label"
                      optionValue="value"
                      [(ngModel)]="selectedCrudOption"
                      (onChange)="handleDropdown($event, item)"
                    ></p-dropdown>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7">No se encontraron datos</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
}

<!-- Modal formulario de creación/edición -->
<p-dialog
  header="Añadir registro"
  [(visible)]="dialogData"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="closeDataForm()"
>
  <div class="form-container">
    <form [formGroup]="form">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="12">
            <label for="">Estudios y análisis realizados</label>
            <textarea
              [style]="{ width: '100%' }"
              class="w-full mt-1 mb-1"
              pInputTextarea
              rows="5"
            ></textarea>
            <p-fileUpload
              name="file"
              chooseLabel="Seleccionar archivo"
              [customUpload]="true"
              [showUploadButton]="false"
              (onSelect)="onFileSelect($event)"
              (onRemove)="deleteFileSelected($event)"
              accept=".pdf"
            ></p-fileUpload>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="">Resumen no técnico comprensible para la comunidad</label>
            <textarea
              [style]="{ width: '100%' }"
              class="w-full mt-1 mb-1"
              pInputTextarea
              rows="5"
            ></textarea>
            <p-fileUpload
              name="file"
              chooseLabel="Seleccionar archivo"
              [customUpload]="true"
              [showUploadButton]="false"
              (onSelect)="onFileSelect($event)"
              (onRemove)="deleteFileSelected($event)"
              accept=".pdf"
            ></p-fileUpload>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for="">Revisar el EsIA para asegurar coherencia y consistencia de la información</label>
            <textarea
              [style]="{ width: '100%' }"
              class="w-full mt-1 mb-1"
              pInputTextarea
              rows="5"
            ></textarea>
            <p-fileUpload
              name="file"
              chooseLabel="Seleccionar archivo"
              [customUpload]="true"
              [showUploadButton]="false"
              (onSelect)="onFileSelect($event)"
              (onRemove)="deleteFileSelected($event)"
              accept=".pdf"
            ></p-fileUpload>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>