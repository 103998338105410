import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyIdHeaderService {
  companyIdSubject: BehaviorSubject<string | null>;

  constructor() {
    const companyId = sessionStorage.getItem('companyId');
    this.companyIdSubject = new BehaviorSubject<string | null>(companyId);
  }

  setCompanyId(companyId: string | null) {
    if (companyId) {
      sessionStorage.setItem('companyId', companyId);
    } else {
      sessionStorage.removeItem('companyId');
    }
    this.companyIdSubject.next(companyId);
  }

  getCompanyId() {
    return this.companyIdSubject.value;
  }

  getCompanyIdObservable() {
    return this.companyIdSubject.asObservable();
  }
}
