import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LifecyclePageRoutingModule } from './lifecycle-routing.module';

import { LifecyclePage } from './lifecycle.page';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    InputTextModule,
    ToastModule,
    TabViewModule,
    InputNumberModule,
    TableModule,
    DropdownModule,
    ConfirmPopupModule,
    DialogModule,
    ConfirmDialogModule,
    LifecyclePageRoutingModule
  ],
  declarations: [LifecyclePage],
  providers: [ConfirmationService, MessageService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LifecyclePageModule {}
