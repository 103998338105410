import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { crudOptionsProducts } from 'src/app/utils';

@Component({
  selector: 'app-inventory-analysis',
  templateUrl: './inventory-analysis.page.html',
  styleUrls: ['./inventory-analysis.page.scss'],
})
export class InventoryAnalysisPage implements OnInit {
  @ViewChild('dt') table: Table;
  loading: boolean = true;
  dataInventory: any[] = [];
  // Formulario dividido en seis partes
  dataForm1: FormGroup;
  dataForm2: FormGroup;
  dataForm3: FormGroup;
  dataForm4: FormGroup;
  dataForm5: FormGroup;
  dataForm6: FormGroup;
  dataSelected: any | null;
  selectedCrudOption: number = 0;
  edit: boolean = false;
  dialogData: boolean = false;
  crudOptionsProducts = crudOptionsProducts;
  uploadedFile: File | null = null;

  constructor(    
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initData();
    this.formInit();
    this.loading = false;
  }

  initData(){
    this.crudOptionsProducts = this.crudOptionsProducts.filter(item => item.value !== 1);
    this.dataInventory = [
      {
        id: 1,
        created_at: '2024-07-01'
      },
      {
        id: 2,
        created_at: '2024-08-05'
      },
      {
        id: 3,
        created_at: '2024-10-09'
      },
      {
        id: 4,
        created_at: '2024-10-12'
      },
      {
        id: 5,
        created_at: '2024-02-11'
      },
    ];
  }

  /**
   * Inicializar formularios
   */
  formInit(){
    this.dataForm1 = this.fb.group({

    });

    this.dataForm2 = this.fb.group({
      
    });

    this.dataForm3 = this.fb.group({
      
    });

    this.dataForm4 = this.fb.group({
      
    });

    this.dataForm5 = this.fb.group({
      
    });

    this.dataForm6 = this.fb.group({
      
    });
  }

  /**
   * Manejar modal de creación/edición de registro
   * @param product 
   */
  showFormDialog(item?: any) {
    if (item) {
      this.dataSelected = item;
    }

    this.edit = item ? true : false;
    this.dialogData = true;
  }

  /**
   * Manejador de subida de archivo de evidencia
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Manejar opción seleccionada en opciones de un producto
   */
  handleDropdown(event: any, item: any){
    const action = event.value;
  
    if(action === 2){
      this.showFormDialog(item);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }

    if(action === 3){
      this.onDeleteClick(item);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }
  }

  /**
   * Enviar formulario de producto
   */
  onSubmit(){
    // console.log(this.dataForm.value);
    this.dialogData = false;
  }

  /**
   * Manejar cierre de modal de creación/edición de producto
   */
  closeDataForm(){
    // this.dataForm.reset();
  }

  /**
   * Manejar eliminación de producto
   * @param prouct 
   */
  onDeleteClick(item: any) {
    this.confirmationService.confirm({
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
