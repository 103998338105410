import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BusinessNetworkingPageRoutingModule } from './business-networking-routing.module';

import { BusinessNetworkingPage } from './business-networking.page';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StepperModule } from 'primeng/stepper';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    ToastModule,
    CardModule,
    DropdownModule,
    ChipModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    InputNumberModule,
    AccordionModule,
    TableModule,
    TooltipModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    StepperModule,
    InputTextModule,
    OrganizationChartModule,
    IonicModule,
    BusinessNetworkingPageRoutingModule,
  ],
  declarations: [BusinessNetworkingPage],
})
export class BusinessNetworkingPageModule {}
