<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>

  <ion-row style="margin-top: -6rem !important">
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <!-- Listado -->
      <p-card header="Interconexión de empresas">
        @if(loading){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </ion-col>
        } @else {
        <div class="w-full mb-1">
          <p-accordion class="accordion-indicator">
            <p-accordionTab header="Organigrama - 2023">
              <!-- Añadir nueva interconexión -->
              <div
                class="flex justify-content-end"
                style="margin-bottom: 1.25rem"
              >
                <p-button
                  label="Nueva interconexión de empresas"
                  icon="pi pi-plus"
                  (click)="showBusinessNetworkingModal()"
                >
                </p-button>
              </div>

              <div class="risk-matrix">
                <p-organizationChart
                  [value]="data"
                  selectionMode="single"
                  [(selection)]="selectedNodes"
                  (onNodeSelect)="showCompanyIndicators($event)"
                >
                  <ng-template let-node pTemplate="company">
                    @if(loadingModal){
                    <div class="spinner-container-chart">
                      <ion-spinner name="dots"></ion-spinner>
                    </div>
                    } @else {
                    <div class="p-2 text-center">
                      <div>{{ node.data.name }}</div>
                    </div>
                    }
                  </ng-template>
                </p-organizationChart>
              </div>
            </p-accordionTab>

            <p-accordionTab header="Organigrama - 2024">
              <div
                class="flex justify-content-end"
                style="margin-bottom: 1.25rem"
              >
                <p-button
                  label="Nueva interconexión de empresas"
                  icon="pi pi-plus"
                  (click)="showBusinessNetworkingModal()"
                >
                </p-button>
              </div>

              <div class="risk-matrix">
                <p-organizationChart
                  [value]="data"
                  selectionMode="single"
                  [(selection)]="selectedNodes"
                  (onNodeSelect)="showCompanyIndicators($event)"
                >
                  <ng-template let-node pTemplate="company">
                    @if(loadingModal){
                    <div class="spinner-container-chart">
                      <ion-spinner name="dots"></ion-spinner>
                    </div>
                    } @else {
                    <div class="p-2 text-center">
                      <div>{{ node.data.name }}</div>
                    </div>
                    }
                  </ng-template>
                </p-organizationChart>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para cumplimentar interconexión de empresas -->
<p-dialog
  header="Nueva interconexión de empresas"
  [(visible)]="dialogNetworking"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '50vw' }"
  (onHide)="handleHideBusinessNetworking()"
>
  <div>
    <p-stepper>
      <p-stepperPanel header="Empresa matriz">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 1 -->
          <div class="">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <p-dropdown
                    [(ngModel)]="mainCompany"
                    appendTo="body"
                    [options]="companiesData"
                    optionLabel="company_name"
                    [style]="{'width':'100%'}"
                    [inputStyle]="{'width':'100%'}"
                    optionValue="id"
                    placeholder="Selecciona una empresa"
                  >
                  </p-dropdown>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>

          <div class="flex pt-4 justify-content-end">
            <p-button
              label="Siguiente"
              [disabled]="!mainCompany"
              icon="pi pi-arrow-right"
              iconPos="right"
              (onClick)="nextCallback.emit()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 2 -->
      <p-stepperPanel header="Empresas filiales">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-index="index"
        >
          <!-- Formulario paso 2 -->
          <form [formGroup]="formSubsidiaryCompanies">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <ion-grid formArrayName="companies">
                    <ion-row
                      *ngFor="let company of companies.controls; let i = index"
                      [formGroupName]="i"
                    >
                      <ion-col size-xs="12" size-sm="12" size-md="6">
                        <span> {{'Filial ' + i}} </span>
                      </ion-col>
                      <ion-col size-xs="12" size-sm="12" size-md="6">
                        <p-inputNumber
                          formControlName="percentage"
                          [style]="{'width':'100%'}"
                          [inputStyle]="{'width':'100%'}"
                          [min]="0"
                          [max]="100"
                          [suffix]="'%'"
                          class="w-full"
                          placeholder="% de afectación"
                        ></p-inputNumber>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>

          <div class="flex pt-4 justify-content-between">
            <p-button
              label="Atrás"
              icon="pi pi-arrow-left"
              (onClick)="prevCallback.emit()"
            />
            <p-button
              label="Guardar"
              iconPos="right"
              (click)="onSubmitForm()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
  </div>
</p-dialog>

<!-- Modal para visualizar indicadores de una compañía -->
<p-dialog
  header="Indicadores de la empresa"
  [(visible)]="dialogCompanyIndicators"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '50vw' }"
  (onHide)="handleHideCompanyIndicators()"
>
  <div>
    <p-table
      #dt1
      styleClass="p-datatable-striped"
      responsiveLayout="scroll"
      [value]="indicators"
      [rowHover]="true"
      [paginator]="true"
      [rows]="10"
      [globalFilterFields]="['name']"
    >
      <ng-template pTemplate="caption">
        <div
          style="gap: 1vw"
          class="flex justify-content-center align-items-center sm:flex-row"
        >
          <input
            pInputText
            type="text"
            #filterGeneral
            (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Buscar indicador"
            class="w-full"
          />
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Nombre del indicador</th>
          <th>Estado</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-indicator>
        <tr>
          <td>{{ indicator.name }}</td>
          <td>
            <p-button
              severity="{{ indicator.disabled === 1 ? 'danger' : 'success' }}"
              class="m-1"
              icon="{{ indicator.disabled === 1 ? 'pi pi-thumbs-down' : 'pi pi-thumbs-up'}}"
              pTooltip="{{ indicator.disabled === 1 ? 'Desactivado' : 'Activado'}}"
            ></p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No se encontraron indicadores</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
