import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InputText } from 'primeng/inputtext';

@Component({
  selector: 'app-initial-state-component',
  templateUrl: './initial-state-component.component.html',
  styleUrls: ['./initial-state-component.component.scss'],
})
export class InitialStateComponentComponent implements OnInit {
  @Input({ required: true }) id!: any;
  idResponsible: any;
  idDepartment: any;
  description: any;
  request: any;
  date: any;
  observations: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
  }


  submitForm() {

  }

}
