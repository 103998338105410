import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { IdentificationAssessmentImpactsPageRoutingModule } from './identification-assessment-impacts-routing.module';

import { IdentificationAssessmentImpactsPage } from './identification-assessment-impacts.page';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StepperModule } from 'primeng/stepper';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService, MessageService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ToastModule,
    TooltipModule,
    DialogModule,
    DropdownModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    CalendarModule,
    InputTextareaModule,
    InputNumberModule,
    StepperModule,
    InputTextareaModule,
    ConfirmDialogModule,
    FileUploadModule,
    InputNumberModule,
    IdentificationAssessmentImpactsPageRoutingModule
  ],
  declarations: [IdentificationAssessmentImpactsPage],
  providers: [ConfirmationService, MessageService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IdentificationAssessmentImpactsPageModule {}
