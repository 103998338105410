import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-initial-state-fields',
  templateUrl: './initial-state-fields.page.html',
  styleUrls: ['./initial-state-fields.page.scss'],
})
export class InitialStateFieldsPage implements OnInit {

  id: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
  }

}
