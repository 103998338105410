<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<ion-grid class="p-6">
  <div class="product-container">
    <div class="product-details">
      <div class="product-image-gallery">
        <div class="main-image-container">
          <img [src]="productDetail?.image" alt="Product Image" class="main-image" />
        </div>
      </div>
      <div class="product-info">
        <h2>{{ productDetail?.name }}</h2>
        <div class="description">
          <h3>Description</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum fermentum nibh non odio 
            tincidunt blandit. Integer eget risus facilisis, hendrerit ex non, imperdiet magna. Proin faucibus rutrum 
            erat ut condimentum. Etiam interdum risus in dui dapibus vestibulum. Donec hendrerit, elit eget ultricies tempor, 
            sem ex feugiat diam, et molestie neque diam imperdiet orci. In facilisis ex pulvinar, dictum sapien vel, 
            interdum enim. Aliquam pharetra eros sed ligula interdum, eget faucibus ex tincidunt. Nunc at arcu facilisis, 
            rutrum mi vel, ornare dolor. Nam aliquet eros magna. Vivamus sollicitudin euismod magna sit amet ultrices. 
            Morbi congue pellentesque ligula, sit amet hendrerit mi placerat nec. Sed dapibus malesuada purus. Maecenas 
            in eros eget turpis tincidunt placerat a sed nisi.
          </p>
        </div>
      </div>
    </div>
  </div>
</ion-grid>