import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor(private toast: MessageService) {}

  /**
   * Funció genérica para mostrar mensajes de aviso
   */
  public showMessage(severity: string, summary: string, detail: string) {
    this.toast.add({
      key: 'tst',
      severity: severity,
      summary: summary,
      detail: detail,
    });
  }

  /**
   * Renombrado de fichero
   * @param file
   * @param newName
   * @returns
   */
  public static renameFile(file: File, newName: string) {
    const blob = file.slice(0, file.size, file.type);

    const splitName = file.name.split('.');
    const ext = splitName[splitName.length - 1];

    return new File([blob], newName + '.' + ext, {
      type: file.type,
    });
  }
}
