import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Product } from 'src/app/models/product';

interface Lifecycle {
  id: number;
  name: string;
  rawMaterials: RawMaterial[];
  production: Production[];
}

interface RawMaterial {
  product: Product;
  quantity: number;
  unit: string;
  cO2: number;
}

interface Production {
  name: string;
}

@Component({
  selector: 'app-lifecycle',
  templateUrl: './lifecycle.page.html',
  styleUrls: ['./lifecycle.page.scss'],
})
export class LifecyclePage implements OnInit {
  @ViewChild('dt') table: Table;
  productSelected: Product | null = null;
  products: Product[] = [];
  dataLifecycle: Lifecycle[] = [];
  productsProd: any[] = [];
  rawMaterials: any[] = [];
  production: any[] = [];
  dialogRawMaterial: boolean = false;
  dialogProduction: boolean = false;
  activeIndex: number = 1;
  productionForm: FormGroup;
  rawMaterialsForm: FormGroup;
  loading: boolean = false;
  itemLifecycleSelected: Lifecycle | null = null;
  subItemSelected: Lifecycle | null = null;

  constructor(
    private messageService: MessageService,
    private fb: FormBuilder,
  ) {
    this.initData();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(){
    this.rawMaterialsForm = this.fb.group({
      productSelected: [''],
      quantity: [''],
      unit: [''],
      cO2: [''],
    });

    this.productionForm = this.fb.group({
      processSelected: [''],
    });
  }

  initData(){
    // Productos para seleccionar    
    this.products = [
      {
        id: 1,
        name: 'Patatas',
        image: 'assets/images/image3.jpg'
      },
      {
        id: 2,
        name: 'Huevos',
        image: 'assets/images/image1.jpg'
      },
      {
        id: 3,
        name: 'Aceite de oliva',
        image: 'assets/images/image2.jpg'
      },
      {
        id: 4,
        name: 'Cebollas',
        image: 'assets/images/image4.jpg'
      },
    ];

    this.productsProd = [
      {
        id: 1,
        name: 'Cocer',
      },
      {
        id: 2,
        name: 'Lavar',
      },
      {
        id: 3,
        name: 'Cortar',
      },
      {
        id: 4,
        name: 'Remover',
      },
    ];


    // Listado de ciclos de vida
    this.dataLifecycle = [
      {
        id: 1,
        name: 'Tortilla de patatas',
        rawMaterials: [
          {
            product: {
              id: 1,
              name: 'Patatas',
              image: 'assets/images/image3.jpg',
            },
            quantity: 1,
            unit: 'kg',
            cO2: 20
          },
          {
            product: {
              id: 1,
              name: 'Aceite de oliva',
              image: 'assets/images/image2.jpg',
            },
            quantity: 1,
            unit: 'kg',
            cO2: 20
          },
          {
            product: {
              id: 1,
              name: 'Huevos',
              image: 'assets/images/image1.jpg',
            },
            quantity: 1,
            unit: 'kg',
            cO2: 20
          }
        ],
        production: [
          {
            name: 'Freir'
          },
          {
            name: 'Cocinar'
          }
        ]
      }
    ]
  }

  /**
   * Capturar valores pulsado de input de número y hacer la equivalencia de kg de materia prima y C02
   * @param event 
   */
  handleInputNumber(event) {
    const cO2Unit = 0.6;
    const co2Control = this.rawMaterialsForm.get('cO2');
    co2Control?.setValue(cO2Unit * event);
    co2Control?.updateValueAndValidity();
  }

  /**
   * Añadir item a inventario de materia prima o producción
   */
  addItem(type: number){
    if(type === 1){
      this.dialogRawMaterial = true;
    }

    if(type === 2){
      this.dialogProduction = true;
    }
  }

  /**
   * Mostrar detalles de ciclo de vida seleccionado
   */
  showDetail(item: Lifecycle){
    this.itemLifecycleSelected = item;
    this.rawMaterials = item.rawMaterials;
    this.production = item.production;
  }

  /**
   * Manejar cierre de modal de materias primas
   */
  closeDialogRawMaterials(){
    this.dialogRawMaterial = false;
    this.productSelected = null;
  }

  /**
   * Manejar cierre de modal de producción
   */
  closeDialogProduction(){
    this.dialogProduction = false;
    this.productSelected = null;
  }

  /**
   * Enviar formulario de materias primas o producción
   * @param type 
   */
  onSubmit(type: number){
    if(type === 1){
      const formValue = this.rawMaterialsForm.value;
      // Añadir al array de materias primas
      this.rawMaterials.push({
        product: formValue.productSelected,
        quantity: formValue.quantity,
        unit: formValue.unit,
        cO2: formValue.cO2
      });

      this.rawMaterials = [...this.rawMaterials];

      this.messageService.add({
        severity:'success',
        summary: 'OK',
        detail: 'Item añadido al inventario de materia prima'
      });
      this.dialogRawMaterial = false;
    }

    if(type === 2){
      const formValue = this.productionForm.value;
      // Añadir al array de materias primas
      this.production.push({
        name: formValue.processSelected.name,
      });

      this.production = [...this.production]; // Clonamos el array para detectar los cambios
      this.messageService.add({
        severity:'success',
        summary: 'OK',
        detail: 'Item añadido al inventario de producción'
      });
      this.productionForm.reset();
      this.dialogProduction = false;
    }
  }
}
