<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
<div class="spinner-container-indicator">
  <ion-spinner name="dots"> </ion-spinner>
  <ion-label>Cargando datos, por favor espere...</ion-label>
</div>
} @else {
<ion-grid class="p-4">
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <h2>Procedimiento para la identificación y evaluación de impactos</h2>
    </ion-col>

    <ion-col size-xs="12" size-sm="12" size-md="12">
      <div class="p-col-12 mb-4 custom-align-right">
        <button
          pButton
          type="button"
          label="Añadir registro"
          icon="pi pi-plus"
          (click)="showFormDialog()"
        ></button>
      </div>

      <div class="table-container p-col-12">
        <p-table
          #dt1
          [value]="dataImpacts"
          [paginator]="true"
          [rows]="10"
          dataKey="id"
          [rowHover]="true"
          [globalFilterFields]="['created_at']"
          styleClass="p-datatable-striped"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="caption">
            <div
              style="gap: 1vw"
              class="flex justify-content-center align-items-center sm:flex-row"
            >
              <input
                pInputText
                type="text"
                #filterGeneral
                (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="Buscar"
                class="w-full"
              />
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Fecha</th>
              <th>Opciones</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-center">
                {{ item?.created_at | date: 'dd/MM/YYYY'}}
              </td>
              <td>
                <div class="flex justify-content-center align-items-center">
                  <p-dropdown
                    [options]="crudOptionsProducts"
                    appendTo="body"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="selectedCrudOption"
                    (onChange)="handleDropdown($event, item)"
                  ></p-dropdown>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No se encontraron datos</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
}

<!-- Modal formulario de creación/edición -->
<p-dialog
  header="Añadir registro"
  [(visible)]="dialogData"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '100vw' }"
  (onHide)="closeDataForm()"
>
  <div class="form-container">
    <form [formGroup]="form">
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for=""
              >Posibles interacciones entre las actividades del proyecto y el
              medio ambiente</label
            >
            <textarea
              [style]="{ width: '100%' }"
              class="w-full mt-1 mb-1"
              pInputTextarea
              rows="5"
            ></textarea>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="6">
            <label for=""
              >Clasificar los impactos según criterios de magnitud, extensión,
              duración y probabilidad</label
            >
            <textarea
              [style]="{ width: '100%' }"
              class="w-full mt-1 mb-1"
              pInputTextarea
              rows="5"
            ></textarea>
          </ion-col>
          <ion-col size-xs="12" size-sm="12" size-md="12">
            <label for="">Matriz de Leopold</label>
            <!-- Agregar nuevas filas -->
            <div class="input-section mt-3 mb-3" (keyup.enter)="addRow()">
              <label for="firstCol">
                <strong>Medio Afectado (primera columna)</strong>
              </label>
              <input
                pInputText
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="newFirstCol"
              />
              <label for="secondCol">
                <strong>Impacto Producido (segunda columna)</strong>
              </label>
              <input
                pInputText
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="newSecondCol"
              />
              <p-button label="Agregar" (click)="addRow()"></p-button>
            </div>

            <!-- Tabla -->
            <div class="table-container">
              <table class="leopold-table">
                <!-- Title Row -->
                <tr>
                  <th colspan="13" class="title-row">Matriz de Leopold</th>
                </tr>

                <!-- Column Headers (Phase Headers) -->
                <tr>
                  <th class="header" rowspan="2">Medio Afectado</th>
                  <th class="header" rowspan="2">Impacto Producido</th>
                  <th class="header phase-header" colspan="5">
                    Fase de Construcción
                  </th>
                  <th class="header phase-header" colspan="6">
                    Fase de Explotación
                  </th>
                </tr>

                <!-- Column Sub-Headers (Vertical Text) -->
                <tr>
                  <th class="header vertical-text phase-construction">
                    Tala y desbroce
                  </th>
                  <th class="header vertical-text phase-construction">
                    Acondiciomamiento del terreno
                  </th>
                  <th class="header vertical-text phase-construction">
                    Cimentación y estructuras
                  </th>
                  <th class="header vertical-text phase-construction">
                    Instalaciones de obra
                  </th>
                  <th class="header vertical-text phase-construction">
                    Tránsito de vehículos pesados
                  </th>
                  <th class="header vertical-text phase-construction">
                    Ocupación de terrenos
                  </th>
                  <th class="header vertical-text phase-construction">
                    Depuración de aguas residuales
                  </th>
                  <th class="header vertical-text phase-construction">
                    Emisiones a la atmósfera
                  </th>
                  <th class="header vertical-text phase-construction">
                    Tránsito de vehículos
                  </th>
                  <th class="header vertical-text phase-construction">
                    Mantenimiento
                  </th>
                  <th class="header vertical-text phase-construction">
                    Generación y manejo de lodos y resíduos
                  </th>
                </tr>

                <!-- Renderiza las filas dinámicamente -->
                <ng-container
                  *ngFor="let group of groups; let groupIndex = index; trackBy: trackByIndex"
                >
                  <ng-container
                    *ngFor="let row of group.rows; let rowIndex = index; trackBy: trackByIndex"
                  >
                    <tr>
                      <!-- Solo muestra la primera columna en la primera fila del grupo -->
                      <td
                        *ngIf="rowIndex === 0"
                        [attr.rowspan]="group.rows.length"
                        class="row-header"
                      >
                        {{ group.firstCol }}
                      </td>
                      <td class="row-header">{{ row.secondCol }}</td>

                      <!-- Celdas editables para valores de impacto usando p-inputNumber -->
                      <td
                        *ngFor="let impact of row.impacts; let impactIndex = index; trackBy: trackByIndex"
                        class="impact-cell"
                      >
                        <p-inputNumber
                          [style]="{ width: '100%' }"
                          [inputStyle]="{ width: '100%' }"
                          [ngModelOptions]="{ standalone: true }"
                          [(ngModel)]="group.rows[rowIndex].impacts[impactIndex]"
                          (onInput)="updateImpact(groupIndex, rowIndex, impactIndex, $event.value)"
                          mode="decimal"
                        ></p-inputNumber>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>
