<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<!--  -->
@if(loading){
<div class="spinner-container-indicator">
  <ion-spinner name="dots"> </ion-spinner>
  <ion-label>Cargando, por favor espere...</ion-label>
</div>
}

<!--  -->
@if(!loading){
<ion-grid class="p-4">
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <h2>Partes interesadas</h2>
    </ion-col>
    @if(!forbidden){
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <p-table
        #dt1
        styleClass="p-datatable-striped"
        responsiveLayout="scroll"
        [value]="parties"
        [rowHover]="true"
        [paginator]="true"
        [rows]="10"
        [globalFilterFields]="['id', 'name', 'company.company_name']"
      >
        <ng-template pTemplate="caption">
          <ion-grid>
            <ion-row>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-md="12"
                size-lg="12"
                size-xl="11"
              >
                <input
                  pInputText
                  type="text"
                  #filterGeneral
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar registro"
                  class="w-full"
                />
              </ion-col>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-md="12"
                size-lg="12"
                size-xl="1"
              >
                @if(permissionService.checkSectionPermission(userByCompany, 19,
                2)){
                <div
                  class="w-full flex align-items-center justify-content-center"
                >
                  <p-button
                    label="Nuevo registro"
                    icon="pi pi-plus"
                    (click)="showPartyDialog()"
                  ></p-button>
                </div>
                }
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Empresa</th>
            <th>Opciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-party>
          <tr>
            <td>{{ party.id }}</td>
            <td>{{ party.name }}</td>
            <td>{{ party.company.company_name }}</td>
            <td>
              @if(permissionService.checkSectionPermission(userByCompany, 19,
              3)){
              <p-button
                class="m-1"
                pTooltip="Editar registro"
                icon="pi pi-pencil"
                (click)="showPartyDialog(party)"
              ></p-button>
              }
              <!--  -->
              @if(permissionService.checkSectionPermission(userByCompany, 19,
              4)){
              <p-button
                severity="danger"
                class="m-1"
                pTooltip="Eliminar registro"
                icon="pi pi-trash"
                (click)="showDeleteParty($event, party)"
              ></p-button>
              }
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No se encontraron datos</td>
          </tr>
        </ng-template>
      </p-table>
    </ion-col>
    } @else {
    <div class="w-full flex justify-content-center align-items-center">
      <h5>No dispone de permiso de lectura en esta sección</h5>
    </div>
    }
  </ion-row>
</ion-grid>
}

<!-- Modal para creación/edición de registro de partes interesadas -->
<p-dialog
  header="Registro de partes interesadas"
  [(visible)]="dialogParty"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="handleClosePartyModal()"
>
  <form [formGroup]="form">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Nombre</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="name"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('name')!.errors && form.get('name')!.touched && form.get('name')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Requisitos</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="requirements"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('requirements')!.errors && form.get('requirements')!.touched && form.get('requirements')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Documento</label>
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            cancelLabel="Cancelar"
            (onSelect)="onFileSelect($event)"
            (onRemove)="deleteFileSelected($event)"
            accept=".pdf"
          ></p-fileUpload>
          @if(selectedParty?.document){
          <div class="card pdf-container" (click)="openDocument()">
            <img src="assets/images/pdf.png" alt="" />
            <div class="mt-2">
              <label>Pulse para abrir el documento existente</label>
            </div>
          </div>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button [loading]="loadingForm" label="Guardar" (click)="onSubmitForm()">
    </p-button>
  </ng-template>
</p-dialog>
