import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { InitialStateFieldsPageRoutingModule } from './initial-state-fields-routing.module';
import { InitialStateFieldsPage } from './initial-state-fields.page';
import { InitialStateComponentComponent } from './initial-state-component/initial-state-component.component';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    InitialStateFieldsPageRoutingModule,
    FileUploadModule,
    InputTextModule,
  ],
  declarations: [InitialStateFieldsPage, InitialStateComponentComponent]
})
export class InitialStateFieldsPageModule { }
