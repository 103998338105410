<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<!--  -->
@if(loading){
<div class="spinner-container-indicator">
  <ion-spinner name="dots"> </ion-spinner>
  <ion-label>Cargando, por favor espere...</ion-label>
</div>
}

<!--  -->
@if(!loading){
<ion-grid class="p-4">
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <h2>Contexto de la organización</h2>
    </ion-col>
    @if(!forbidden){
    <ion-col size-xs="12" size-sm="12" size-md="12">
      <p-table
        #dt1
        styleClass="p-datatable-striped"
        responsiveLayout="scroll"
        [value]="contexts"
        [rowHover]="true"
        [paginator]="true"
        [rows]="10"
        [globalFilterFields]="['id', 'company.company_name']"
      >
        <ng-template pTemplate="caption">
          <ion-grid>
            <ion-row>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-md="12"
                size-lg="12"
                size-xl="11"
              >
                <input
                  pInputText
                  type="text"
                  #filterGeneral
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar registro"
                  class="w-full"
                />
              </ion-col>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-md="12"
                size-lg="12"
                size-xl="1"
              >
                @if(permissionService.checkSectionPermission(userByCompany, 18,
                2)){
                <div
                  class="w-full flex align-items-center justify-content-center"
                >
                  <p-button
                    label="Nuevo registro"
                    icon="pi pi-plus"
                    (click)="showContextDialog()"
                  ></p-button>
                </div>
                }
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Empresa</th>
            <th>Opciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-context>
          <tr>
            <td>{{ context.id }}</td>
            <td>{{ context.company.company_name }}</td>
            <td>
              @if(permissionService.checkSectionPermission(userByCompany, 18,
              3)){
              <p-button
                class="m-1"
                pTooltip="Editar registro"
                icon="pi pi-pencil"
                (click)="showContextDialog(context)"
              ></p-button>
              }
              <!--  -->
              @if(permissionService.checkSectionPermission(userByCompany, 18,
              4)){
              <p-button
                severity="danger"
                class="m-1"
                pTooltip="Eliminar registro"
                icon="pi pi-trash"
                (click)="showDeleteContextDialog($event, context)"
              ></p-button>
              }
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No se encontraron datos</td>
          </tr>
        </ng-template>
      </p-table>
    </ion-col>
    } @else {
    <div class="w-full flex justify-content-center align-items-center">
      <h5>No dispone de permiso de lectura en esta sección</h5>
    </div>
    }
  </ion-row>
</ion-grid>
}

<!-- Modal para creación/edición de registro de contexto de organización -->
<p-dialog
  header="Registro de contexto de organización"
  [(visible)]="dialogContext"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="handleCloseContextModal()"
>
  <form [formGroup]="form">
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Tamaño</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="size"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('size')!.errors && form.get('size')!.touched && form.get('size')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Estructura</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="structure"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('structure')!.errors && form.get('structure')!.touched && form.get('structure')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Ubicaciones</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="locations"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('locations')!.errors && form.get('locations')!.touched && form.get('locations')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Sectores</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="sectors"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('sectors')!.errors && form.get('sectors')!.touched && form.get('sectors')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Tipo</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="nature"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('nature')!.errors && form.get('nature')!.touched && form.get('nature')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Escala</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="scale"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('scale')!.errors && form.get('scale')!.touched && form.get('scale')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Complejidad</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="complexity"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('complexity')!.errors && form.get('complexity')!.touched && form.get('complexity')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Miembros</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="members"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('members')!.errors && form.get('members')!.touched && form.get('members')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Relaciones</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="relations"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('relations')!.errors && form.get('relations')!.touched && form.get('relations')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Obligaciones</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="obligations"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('obligations')!.errors && form.get('obligations')!.touched && form.get('obligations')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Comentarios</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="comments"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('comments')!.errors && form.get('comments')!.touched && form.get('comments')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Consejo</label>
          <textarea
            pInputTextarea
            class="w-full mt-1"
            rows="5"
            formControlName="boards"
          ></textarea>
          <span
            class="flex justify-content-center text-center m-2"
            style="color: red; font-size: 12px"
            *ngIf="form.get('boards')!.errors && form.get('boards')!.touched && form.get('boards')!.hasError('required')"
            >Requerido</span
          >
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>*Fecha</label>
          <div class="mt-1">
            <p-calendar
              appendTo="body"
              class="calendar-form"
              formControlName="date"
              dateFormat="dd/mm/yy"
              [inline]="true"
            />
            <span
              class="flex justify-content-center text-center m-2"
              style="color: red; font-size: 12px"
              *ngIf="form.get('date')!.errors && form.get('date')!.touched && form.get('date')!.hasError('required')"
              >Requerido</span
            >
          </div>
        </ion-col>
        <ion-col size-xs="12" size-sm="12" size-md="6">
          <label>Documento</label>
          <p-fileUpload
            name="file"
            chooseLabel="Seleccionar archivo"
            [customUpload]="true"
            [showUploadButton]="false"
            cancelLabel="Cancelar"
            (onSelect)="onFileSelect($event)"
            (onRemove)="deleteFileSelected($event)"
            accept=".pdf"
          ></p-fileUpload>
          @if(selectedContext?.document){
          <div class="card pdf-container" (click)="openDocument()">
            <img src="assets/images/pdf.png" alt="" />
            <div class="mt-2">
              <label>Pulse para abrir el documento existente</label>
            </div>
          </div>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <ng-template pTemplate="footer">
    <p-button [loading]="loadingForm" label="Guardar" (click)="onSubmitForm()">
    </p-button>
  </ng-template>
</p-dialog>
