import { ChartModule } from 'primeng/chart';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DashboardPageRoutingModule } from './dashboard-routing.module';

import { DashboardPage } from './dashboard.page';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChipModule,
    SelectButtonModule,
    ButtonModule,
    InputNumberModule,
    ListboxModule,
    DialogModule,
    DropdownModule,
    ToastModule,
    MessagesModule,
    TooltipModule,
    ConfirmPopupModule,
    IonicModule,
    DashboardPageRoutingModule,
    ChartModule,
  ],
  declarations: [DashboardPage],
  providers: [ConfirmationService, MessageService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardPageModule {}
