<div *ngIf="jsonData && dataLoaded" class="main-container">
  <!-- Comprueba si jsonData tiene datos y si dataLoaded es verdadero -->
  <ng-container *ngTemplateOutlet="generateForm; context:{ $implicit: jsonData, nivel: nivel+1 }"></ng-container>
  <!-- Renderiza el template generateForm y pasa jsonData como contexto -->
</div>

<div class="main-container">
  <ng-template #generateForm let-data let-nivel="nivel">
    <!-- Define un template llamado generateForm con parámetros data y nivel -->
    <div *ngFor="let key of getKeys(data); let index = index">
      <!-- Itera sobre las claves de data -->
      <div *ngIf="data[key].tipo" class="mt-3">
        <!-- Comprueba si el campo actual tiene un tipo definido -->
        <div
          *ngIf="extractOperation(data[key].tipo) === 'SUMA' || extractOperation(data[key].tipo) === 'RESTA' || extractOperation(data[key].tipo) === 'MULTIPLICACION' || extractOperation(data[key].tipo) === 'DIVISION'">
          <!-- Muestra un botón oculto para ejecutar operaciones antes del envío -->
          <button class="hidden" #operationButton
            (click)="this.extraerValoresDesdeOperador(data[key].tipo, this.extractOperation(data[key].tipo), key)">sumatorio</button>
        </div>
        <div
          *ngIf="!(extractOperation(data[key].tipo) === 'SUMA' || extractOperation(data[key].tipo) === 'RESTA' || extractOperation(data[key].tipo) === 'MULTIPLICACION' || extractOperation(data[key].tipo) === 'DIVISION')">
          <!-- Renderiza un campo de entrada si el tipo del campo no es una operación -->
          <div [ngClass]="'header-' + (nivel+1) + ' mb-4 mt-4 bold no-cursiva'">{{ removeCampoSuffix(key) }}</div>
          <!-- Muestra la etiqueta del campo -->
          <ng-container *ngFor="let label of labels; let label_number = index" class="p-fluid p-formgrid grid">
            <span class="field col-12 md:col-4">
              <label class="text-900 font-medium mb-2 mr-2">{{label}}</label>
              <!-- Renderiza un campo de entrada de texto -->
              <input type="{{deleteSuffix(data[key].tipo)}}" pInputText placeholder="{{typeHasSuffix(data[key].tipo)}}"
                class="mb-3" (change)="saveType(key, $event, label_number, typeHasSuffix(data[key].tipo))">
            </span>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!data[key].tipo" class="mt-4">
        <!-- Si el campo actual no tiene un tipo definido, es un objeto anidado -->
        <span [ngClass]="'header-' + (nivel)">{{ key }}</span>
        <!-- Muestra la clave del objeto anidado -->
        <ng-container *ngTemplateOutlet="generateForm; context: {$implicit: data[key], nivel: nivel+1 }"></ng-container>
        <!-- Renderiza los campos del objeto anidado llamando recursivamente a generateForm -->
      </div>
    </div>
  </ng-template>

  <p-messages></p-messages>

  <!-- Componente para mostrar mensajes de error o éxito -->

  <div class="mt-5">
    <label class="fs-5 fw-bold" for="documento">Documento:</label>
    <!-- Componente para cargar archivos -->
    <p-fileUpload class="mt-3" name="documento" url="/upload" (onSelect)="onFileSelect($event)"></p-fileUpload>
  </div>

  <div class="mt-5">
    <!-- Botón para enviar el formulario -->
    <p-button #submitButton label="Enviar" class="p-button-primary" (click)="sendData()"></p-button>
  </div>
</div>