<h3>Estado Inicial del indicador {{this.id}}</h3>
<div class="mt-5" *ngIf="!isJsonDataEmpty()">
  <div class="indicators-fields">
    <label class="fs-5 fw-bold" for="documento"
      >Rellenar campos del indicador:</label
    >
    <br />
    <button
      pButton
      type="button"
      label="Rellenar Campos"
      (click)="openModal()"
    ></button>
    <p-dialog
      [draggable]="false"
      [resizable]="false"
      [(visible)]="modal"
      id="modal"
      [modal]="true"
    >
      <app-autoform-component
        [jsonData]="this.jsonData"
        [labels]="this.labels"
        [id]="this.id"
      ></app-autoform-component>
    </p-dialog>
  </div>
</div>
