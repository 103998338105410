<form (ngSubmit)="submitForm()" class="p-fluid">
  <!--<div class="p-field">
    <label for="id_responsible">ID Responsible</label>
    <input id="id_responsible" type="text" pInputText [(ngModel)]="idResponsible" name="id_responsible">
  </div>
  <div class="p-field">
    <label for="id_department">ID Department</label>
    <input id="id_department" type="text" pInputText [(ngModel)]="idDepartment" name="id_department">
  </div>-->
  <div class="p-field mt-4">
    <label for="description">Description</label>
    <input id="description" type="text" pInputText [(ngModel)]="description" name="description">
  </div>
  <div class="p-field mt-4">
    <label for="request">Request</label>
    <input id="request" type="text" pInputText [(ngModel)]="request" name="request">
  </div>
  <div class="p-field mt-4">
    <label for="date">Date</label>
    <input id="date" type="date" pInputText [(ngModel)]="date" name="request">
  </div>
  <div class="p-field mt-4">
    <label for="observations">Observations</label>
    <input id="observations" type="text" pInputText [(ngModel)]="observations" name="observations">
  </div>
  <button type="submit" pButton label="Submit" class="p-button-success mt-4"></button>
</form>