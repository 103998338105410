<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label>Cargando productos, por favor espere...</ion-label>
  </div>
} @else {
  <ion-grid class="p-4">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <h2>Inventario de productos</h2>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="p-col-12 mb-4 custom-align-right">
          <button
            pButton
            type="button"
            label="Crear producto"
            icon="pi pi-plus"
            (click)="showProductDialog()"
          ></button>
        </div>
        <div class="table-container p-col-12">
          <p-table 
            #dt1
            [value]="products" 
            [paginator]="true" 
            [rows]="10"
            dataKey="id"
            [rowHover]="true"
            [globalFilterFields]="['name']"
            styleClass="p-datatable-striped"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="caption">
              <div
                style="gap: 1vw"
                class="flex justify-content-center align-items-center sm:flex-row"
              >
                <input
                  pInputText
                  type="text"
                  #filterGeneral
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar"
                  class="w-full"
                />
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>Nombre</th>
                <th>Opciones</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr>
                <td class="flex justify-content-center align-items-center">
                  @defer(){
                    <ion-avatar>
                      <img [src]="product?.image" alt="">
                    </ion-avatar>
                  }
                </td>
                <td class="text-center">
                  {{ product?.name }}
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p-dropdown              
                      [options]="crudOptionsProducts"
                      appendTo="body"
                      optionLabel="label"
                      optionValue="value"
                      [(ngModel)]="selectedCrudOption"
                      (onChange)="handleDropdown($event, product)"
                    ></p-dropdown>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
}

<!-- Modal para creación/eliminación de producto -->
<p-dialog
  header="Formulario de producto"
  [(visible)]="dialogProduct"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="closeProductClose()"
>
  <div class="form-container">
    <form [formGroup]="productForm" enctype="multipart/form-data">
      <div class="container-img">
        <div class="custom-file-upload" *ngIf="mainStringPhoto">
          <img [src]="mainStringPhoto" style="width: 15vw" alt="Foto cargada" />
          <button
            class="ml-2"
            type="button"
            pButton
            icon="pi pi-trash"
            (click)="deletePhoto()"
          ></button>
        </div>
        <div class="custom-file-upload">
          <input
            id="photoInput2"
            type="file"
            (change)="handleUploadPhoto($event)"
            accept=".jpg,.png"
          />
          <label class="btn-img">Selecciona imagen de producto</label>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col size-xs="12" size-sm="12" size-md="12">
            <label>*Nombre</label>
            <input
              formControlName="name"
              pInputText
              class="mt-1 w-full"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>