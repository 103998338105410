<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if(loading){
  <div class="spinner-container-indicator">
    <ion-spinner name="dots"> </ion-spinner>
    <ion-label>Cargando datos, por favor espere...</ion-label>
  </div>
} @else {
  <ion-grid class="p-4">
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="12">
        <h2>Análisis de inventario</h2>
      </ion-col>

      <ion-col size-xs="12" size-sm="12" size-md="12">
        <div class="p-col-12 mb-4 custom-align-right">
          <button
            pButton
            type="button"
            label="Añadir registro"
            icon="pi pi-plus"
            (click)="showFormDialog()"
          ></button>
        </div>

        <div class="table-container p-col-12">
          <p-table 
            #dt1
            [value]="dataInventory" 
            [paginator]="true" 
            [rows]="10"
            dataKey="id"
            [rowHover]="true"
            [globalFilterFields]="['name']"
            styleClass="p-datatable-striped"
            responsiveLayout="scroll"
          >
            <ng-template pTemplate="caption">
              <div
                style="gap: 1vw"
                class="flex justify-content-center align-items-center sm:flex-row"
              >
                <input
                  pInputText
                  type="text"
                  #filterGeneral
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Buscar"
                  class="w-full"
                />
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Fecha</th>
                <th>Opciones</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td class="text-center">
                  {{ item?.created_at | date: 'dd/MM/YYYY'}}
                </td>
                <td>
                  <div class="flex justify-content-center align-items-center">
                    <p-dropdown              
                      [options]="crudOptionsProducts"
                      appendTo="body"
                      optionLabel="label"
                      optionValue="value"
                      [(ngModel)]="selectedCrudOption"
                      (onChange)="handleDropdown($event, item)"
                    ></p-dropdown>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7">No se encontraron datos</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
}

<!-- Modal para creación/edición -->
<p-dialog
  header="Añadir registro"
  [(visible)]="dialogData"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '90vw' }"
  (onHide)="closeDataForm()"
>
  <div class="form-container">
    <p-stepper>
      <!-- Paso 1 -->
      <p-stepperPanel header="Paso 1">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm1">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <h3>Definir el alcance y los límites del sistema</h3>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Alcance del LCI</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mt-1"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                        Define qué procesos incluirás y cuáles excluirás. Esto dependerá de los límites del sistema 
                        establecidos durante la fase de definición del alcance del ACV
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Unidad funcional</label>
                  <input
                    pInputText
                    class="mt-1 w-full"
                  />
                  <div class="text-center">
                    <small>
                        Establece una unidad funcional clara que permitirá cuantificar todas las entradas y 
                        salidas de manera uniforme
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Límite geográfico</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Límite temporal</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Límite tecnológico</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>
      
      <!-- Paso 2 -->
      <p-stepperPanel header="Paso 2">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm2">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <h3>Recolección de datos de inventario</h3>
              </div>
              <!-- Primera parte -->
              <div>
                <h4>Identificación de flujos</h4>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Materias primas</label>
                  <p-inputNumber 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    inputId="minmaxfraction" 
                    mode="decimal" 
                    class="w-full"
                    [maxFractionDigits]="6" 
                  />
                  <div class="text-center mb-3">
                    <small>
                        Cuantificar el uso de materiales
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Energía</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                        Recopilar datos sobre el consumo energético
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Agua</label>
                  <p-inputNumber 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    inputId="minmaxfraction" 
                    mode="decimal" 
                    class="w-full"
                    [maxFractionDigits]="6" 
                  /> 
                  <div class="text-center mb-3">
                    <small>
                        Cuantificar el uso de agua, ya sea para consumo directo o procesos industriales
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Residuos y emisiones</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                        Medir los residuos sólidos, generados, así como emisiones al aire, al agua y al suelo
                    </small>
                  </div>
                </ion-col>
              </ion-row>

              <!-- Segunda parte -->
              <div>
                <h4>Fuente de datos</h4>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Datos primarios</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                        Información directa de los procesos productivos, obtenida de registros internos, 
                        mediciones o datos contables
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Datos secundarios</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                        Datos disponibles en base de datos de ACV o literatura científica, 
                        utilizados cuando no se dispone de datos primarios
                    </small>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 3 -->
      <p-stepperPanel header="Paso 3">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm3">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <h3>Asignación de flujos</h3>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">A</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                      Lo deben definir los proveedores mediante un requisito o cuestionario
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">B</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                      Base de datos de la última página del documento
                    </small>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 4 -->
      <p-stepperPanel header="Paso 4">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm4">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <h3>Validación de datos</h3>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Evaluación de la calidad de los datos</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                      Realiza una evaluación de calidad de los datos, considerando la exactitud, completitud, 
                      representatividad y coherencia
                    </small>
                  </div>
                </ion-col>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Auditorías internas</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="5"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                      Verifica los datos mediante auditorías internas o con el apoyo de un 
                      experto externo en análisis de ciclo de vida
                    </small>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 5 -->
      <p-stepperPanel header="Paso 5">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm5">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <strong>Cálculo de flujos de inventario</strong>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Interacciones entre procesos</label>
                  <textarea 
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    pInputTextarea
                    rows="6"
                  ></textarea>
                  <div class="text-center mb-3">
                    <small>
                      Estos cálculos deben considerar las interacciones entre procesos y 
                      sumar todos los recursos y emisiones
                    </small>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 6 -->
      <p-stepperPanel header="Paso 6">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <form [formGroup]="dataForm6">
            <ion-grid>
              <div class="w-full flex align-items-center justify-content-center">
                <strong>Documentación y registro de resultados</strong>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Documentación completa (informe de producto)</label>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Guardar" [loading]="loadingForm" (click)="onSubmit()">
    </p-button>
  </ng-template>
</p-dialog>