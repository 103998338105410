import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { crudOptionsProducts } from 'src/app/utils';

interface ImpactRow {
  secondCol: string;
  impacts: number[];
}

interface ImpactGroup {
  firstCol: string;
  rows: ImpactRow[];
}

@Component({
  selector: 'app-identification-assessment-impacts',
  templateUrl: './identification-assessment-impacts.page.html',
  styleUrls: ['./identification-assessment-impacts.page.scss'],
})
export class IdentificationAssessmentImpactsPage implements OnInit {
  @ViewChild('dt') table: Table;
  loading: boolean = true;
  dataImpacts: any[] = [];
  dataSelected: any | null;
  selectedCrudOption: number = 0;
  edit: boolean = false;
  dialogData: boolean = false;
  crudOptionsProducts = crudOptionsProducts;
  uploadedFile: File | null = null;
  form: FormGroup;
  loadingForm: boolean = false;
  // Tabla dinámica
  groups: ImpactGroup[] = []; // Array para almacenar grupos de filas
  newFirstCol: string = '';
  newSecondCol: string = '';
  impactValues: number[] = Array(11).fill(0);  // Inicializar los valores de las celdas
  
  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initData();
    this.formInit();
    this.loading = false;
  }
  
  initData(){
    this.crudOptionsProducts = this.crudOptionsProducts.filter(item => item.value !== 1);
    this.dataImpacts = [
      {
        id: 1,
        created_at: '2024-07-01'
      },
      {
        id: 2,
        created_at: '2024-08-05'
      },
      {
        id: 3,
        created_at: '2024-10-09'
      },
      {
        id: 4,
        created_at: '2024-10-12'
      },
      {
        id: 5,
        created_at: '2024-02-11'
      },
    ];
    this.groups = [
      {
        firstCol: 'Primer grupo',
        rows: [
          {
            secondCol: 'Primer subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          },
          {
            secondCol: 'Segundo subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          }
        ]
      },
      {
        firstCol: 'Segundo grupo',
        rows: [
          {
            secondCol: 'Primer subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          },
        ]
      },
      {
        firstCol: 'Tercer grupo',
        rows: [
          {
            secondCol: 'Primer subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          },
          {
            secondCol: 'Segundo subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          },
          {
            secondCol: 'Tercer subgrupo',
            impacts: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          },
        ]
      }
    ];
  }

  /**
   * Inicializar formulario
   */
  formInit(){
    this.form = this.fb.group({});
  }

  /**
   * Añadir filas de primera y/o segunda columna
   * @returns 
   */
  addRow() {
    if (!this.newFirstCol || !this.newSecondCol) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Por favor, ingrese el medio afectado y el impacto producido.',
      });
    }

    // Busca si el grupo de la primera columna ya existe
    let group = this.groups.find(g => g.firstCol === this.newFirstCol);

    if (!group) {
      // Si no existe, crea un nuevo grupo y lo añado a groups
      group = { firstCol: this.newFirstCol, rows: [] };
      this.groups.push(group);
    }

    // Agrega una nueva fila al grupo existente o recién creado
    const newRow: ImpactRow = {
      secondCol: this.newSecondCol,
      impacts: [...this.impactValues] // Crear una copia independiente de los valores de impacto
    };
    group.rows.push(newRow);

    // Limpia los campos de entrada después de agregar la fila
    this.newSecondCol = '';
    this.newFirstCol = '';
  }

  /**
   * Actualizar valores impacto
   * @param groupIndex indice del grupo al que pertenece la fila
   * @param rowIndex indice de la fila dentro del grupo
   * @param impactIndex indice del valor de impacto dentro de impacts
   * @param value nuevo valor del input
   */
  updateImpact(groupIndex: number, rowIndex: number, impactIndex: number, value: number) {
    this.groups[groupIndex].rows[rowIndex].impacts[impactIndex] = value;
  }

  /**
   * Controlar indices que se está editando
   * @param index 
   * @returns 
   */
  trackByIndex(index: number): number {
    return index;
  }

  showFormDialog(item?: any) {
    if (item) {
      this.dataSelected = item;
    }

    this.edit = item ? true : false;
    this.dialogData = true;
  }

  /**
   * Manejador de subida de archivo de evidencia
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }
  
  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Manejar opción seleccionada en opciones de un producto
   */
  handleDropdown(event: any, item: any){
    const action = event.value;
  
    if(action === 2){
      this.showFormDialog(item);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }

    if(action === 3){
      this.onDeleteClick(item);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }
  }

  /**
   * Enviar formulario
   */
  onSubmit(){
    console.log(this.groups);
    // this.dialogData = false;
  }
  
  /**
   * Manejar cierre de modal de creación/edición de producto
   */
  closeDataForm(){
    // this.dataForm.reset();
  }
  
  /**
   * Manejar eliminación de producto
   * @param prouct 
   */
  onDeleteClick(item: any) {
    this.confirmationService.confirm({
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
