import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ServerService } from 'src/app/services/server.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { formOptions } from 'src/app/utils';

@Component({
  selector: 'app-users-employees',
  templateUrl: './users-employees.page.html',
  styleUrls: ['./users-employees.page.scss'],
})
export class UsersEmployeesPage implements OnInit {
  formUser: FormGroup;
  formDetail: FormGroup;
  companyId: string | null = null;
  loading: boolean = true;
  users: any[] = [];
  dialogUser: boolean = false;
  dialogDetail: boolean = false;
  selectedUser: any;
  selectedDetailUser: any;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  edit: boolean = false;
  formOptions = formOptions;
  formSelected: number = 1;
  boolAzure: boolean = false;
  loadingForm: boolean = false;
  user: any;
  uploadedFile: File | null = null;
  itemsEmployees: any[] = [];

  constructor(
    private serverService: ServerService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    this.companyId = sessionStorage.getItem('companyId');
  }

  async ngOnInit() {
    // Formulario basico
    this.formUser = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.minLength(8)],
      repeat_password: [''],
    });

    // Formulario específico
    this.formDetail = this.fb.group({
      responsible: [''],
      entry_date: [''],
      document: [''],
      document_hash: [''],
    });

    this.getEmployees();
    await this.getDropdownData();
  }

  /**
   * Obtener listado de usuario clientes de una empresa
   */
  getEmployees() {
    this.serverService
      .getDataUsersApi(`/api/companies/${this.companyId}/employees`)
      .subscribe({
        next: (response) => {
          this.users = response.data ? response.data : [];
          this.loading = false;
        },
        error: (err) => {
          console.error('Error al obtener los usuarios empleados', err);
          this.loading = false;
        },
      });
  }

  /**
   * Manejador de modal de usuario
   * @param user
   */
  showUserDialog(user?) {
    if (user) {
      this.formUser.patchValue(user.user);
      this.selectedUser = user;
      this.mainStringPhoto = user.image;
    }

    this.serverService.getAzureUsersEnv().subscribe((data) => {
      this.boolAzure = data ? true : false;
      this.dialogUser = true;
      this.edit = user ? true : false;
    });
  }

  /**
   * Manejador de cierre de modal de cliente
   */
  closeUserModal() {
    this.formUser.reset();
  }

  /**
   * Manejador para imágen principal
   * @param $event
   * @returns
   */
  public handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = UtilitiesService.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error('Error al manejar imagen adjunta', error);
    }
  }

  /**
   * Eliminar foto adjunta al formulario
   */
  public deletePhoto() {
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Enviar formulario de usuario básico y luego como asociado
   */
  async onSubmitUser() {
    this.formUser.markAllAsTouched();
    if (this.formUser.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    this.loadingForm = true;

    // Procesamos datos del formulario como FormData
    const formValue = this.formUser.value;
    const formData = new FormData();
    if (this.mainPhoto) {
      formData.append('image', this.mainPhoto);
    }
    formData.append('name', formValue.name.toString());
    formData.append('email', formValue.email.toString());
    if (formValue.password) {
      formData.append('password', formValue.password.toString());
    }

    if (formValue.repeat_password) {
      formData.append('passwordConfirm', formValue.repeat_password.toString());
    }

    // Creamos o editamos usuario básico
    try {
      // EDITAR
      if (this.edit) {
        const userResponse = await lastValueFrom(
          this.serverService.sendDataUsersApi(
            '/api/users/' + this.selectedUser.id,
            formData
          )
        );

        if (userResponse) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Usuario actualizado con éxito',
          });
          this.getEmployees();
          this.loadingForm = false;
          this.dialogUser = false;
        }
      }
      // CREAR
      else {
        const userResponse = await lastValueFrom(
          this.serverService.sendDataUsersApi('/api/users', formData)
        );

        if (userResponse) {
          this.user = userResponse.data;
          const data = [{ user_id: this.user.id }];

          const employeeResponse = await lastValueFrom(
            this.serverService.sendDataUsersApi(
              `/api/companies/${this.companyId}/employees/`,
              data
            )
          );

          if (employeeResponse) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Usuario creado con éxito',
            });
            this.getEmployees();
            this.loadingForm = false;
            this.dialogUser = false;
          }
        }
      }
    } catch (err: any) {
      console.error('Error al crear/editar usuario', err);
      this.loadingForm = false;
      if (err.error.errors.email) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Email ya en uso, inténtelo con otro diferente',
        });
      }
    }
  }

  /**
   * Enviar formulario de datos específicos de usuario
   */
  onSubmitDetail() {
    this.formDetail.markAllAsTouched();

    // Procesar datos del formulario
    const formValue = this.formDetail.value;
    const formData = new FormData();

    // Tratar el campo 'entry_date' individualmente
    if (formValue.entry_date) {
      // Transformar la fecha usando DatePipe
      const formattedDate = this.datePipe.transform(
        formValue.entry_date,
        'yyyy-MM-dd'
      ); // Formato de ejemplo: 'yyyy-MM-dd'
      if (formattedDate) {
        formData.append('entry_date', formattedDate);
      }
    }

    // Recorrer los otros campos del formulario y agregar solo los que tienen valor
    for (const key in formValue) {
      if (
        formValue.hasOwnProperty(key) &&
        formValue[key] !== null &&
        formValue[key] !== '' &&
        key !== 'entry_date'
      ) {
        formData.append(key, formValue[key]);
      }
    }

    this.loadingForm = true;

    this.serverService
      .sendDataUsersApi(
        `/api/companies/${this.companyId}/employees/${this.selectedDetailUser['employees'][0].id}`,
        formData
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Datos específicos del usuario actualizados con éxito',
            });
          }
          this.dialogDetail = false;
          this.loadingForm = false;
          this.getEmployees();
        },
        error: (err) => {
          console.error(
            'Error al actualizar los datos específicos del usuario',
            err
          );
          this.loadingForm = false;
        },
      });
  }

  /**
   * Manejador de alerta para eliminar usuario
   * @param userId
   */
  onDeleteClick(event, employee) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este usuario empleado?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService
          .deleteDataUsersApi(
            `/api/companies/${this.companyId}/employees/${employee.id}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Usuario eliminado correctamente',
                });
                this.getEmployees();
              }
            },
            error: (error) => {
              console.error('Error al eliminar el usuario', error);
            },
          });
      },
    });
  }

  /**
   * Manejador de apertura de modal de formulario de datos de cliente
   * @param user
   */
  showDetailDialog(user) {
    this.dialogDetail = true;
    this.selectedDetailUser = user;
    this.formDetail.patchValue({
      ...user['employees'][0],
      entry_date: user['employees'][0].entry_date
        ? new Date(user['employees'][0].entry_date)
        : null,
    });
  }

  /**
   * Manejador de cierre de modal de formulario de cliente
   */
  closeDetailModal() {
    this.formDetail.reset();
  }

  /**
   * Manejador para el selector de documentos
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Obtener listado de departamentos, empleados y frecuencias para tratarlos con desplegables
   */
  async getDropdownData() {
    try {
      // Carga de listado de empleados
      const employeesPromise = lastValueFrom(
        this.serverService.getDataUsersApi(
          `/api/companies/${this.companyId}/employees`
        )
      ).then((response) => {
        this.itemsEmployees = response.data
          ? response.data.flatMap((item) =>
              item.employees.map((employee) => ({
                name: item.name,
                id: employee.id,
                employee_department_id: employee.department_id,
              }))
            )
          : [];
      });

      // Ejecutar todas las promesas en paralelo
      await Promise.all([employeesPromise]);
    } catch (error) {
      console.error('Error fetching dropdown data', error);
    }
  }
}
