import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Product } from 'src/app/models/product';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { crudOptionsProducts } from 'src/app/utils';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.page.html',
  styleUrls: ['./processes.page.scss'],
})
export class ProcessesPage implements OnInit {
  @ViewChild('dt') table: Table;
  edit: boolean = false;
  dialogItem: boolean = false;
  processes: any[] = [];
  loading: boolean = true;
  crudOptionsProducts = crudOptionsProducts;
  selectedCrudOption: number = 0;
  loadingForm: boolean = false;
  uploadedFile: File | null = null;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  productForm: FormGroup;
  productSelected: Product | null;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.processes = [
      {
        id: 1,
        name: 'Freir',
        image: 'assets/images/image8.jpg'
      },
      {
        id: 2,
        name: 'Cocer',
        image: 'assets/images/image7.jpg'
      },
      {
        id: 3,
        name: 'Lavar',
        image: 'assets/images/image5.jpg'
      },
      {
        id: 4,
        name: 'Cortar',
        image: 'assets/images/image6.jpg'
      },
    ];

    // Inicializar formulario
    this.formInit();
    this.loading = false;
  }

  formInit(){
    this.productForm = this.fb.group({
      name: [''],

    });
  }

  showDataDialog(item?: any) {
    if (item) {
      this.productForm.patchValue(item);
      this.productSelected = item;
      this.mainStringPhoto = item.image;
    }

    this.edit = item ? true : false;
    this.dialogItem = true;
  }

  /**
   * Manejar opción seleccionada en opciones de un producto
   */
  handleDropdown(event: any, product: Product){
    const action = event.value;

    if (action === 1) {      
      this.selectedCrudOption = 0;

      this.router.navigateByUrl('/product-detail', {
        state: { product }
      });
    }

    if(action === 2){
      this.showDataDialog(product);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }

    if(action === 3){
      this.onDeleteClick(product);
      // Pequeño retardo para aplicar al desplegable
      setTimeout(() => {
        this.selectedCrudOption = 0;
      })
    }
  }

  /**
   * Manejar eliminación de producto
   * @param prouct 
   */
  onDeleteClick(item: any) {
    this.confirmationService.confirm({
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
      },
      reject: () => {
      }
    });
  }
  
  /**
   * Enviar formulario de producto
   */
  onSubmit(){}

  /**
   * Manejar cierre de modal de creación/edición de producto
   */
  closeProductClose(){
    this.productForm.reset();
    this.deletePhoto();
  }
  
  /**
   * Eliminar imagen adjunta al formulario
   */
  deletePhoto(){
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }
    
  /**
   * Manejar imagen adjunta al formulario
   */
  handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = UtilitiesService.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  }
}
