import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AutoformEditPageRoutingModule } from './autoform-edit-routing.module';
import { AutoformEditPage } from './autoform-edit.page';
import { AutoFormComponent } from './autoform/autoform.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AutoformEditPageRoutingModule,
    ButtonModule,
    InputTextModule,
    MessagesModule,
    DialogModule,
    FileUploadModule
  ],
  declarations: [AutoformEditPage, AutoFormComponent]
})
export class AutoformEditPageModule { }
