import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor() {}

  /**
   * Comprobar un permiso del usuario actual en la sección correspondiente
   */
  checkSectionPermission(
    user: any,
    sectionId: number,
    permissionId: number
  ): boolean {
    // Busco la sección según sectionId
    if (user && user['sections']) {
      const section = user['sections'].find(
        (section: any) => section.id === sectionId
      );

      if (!section) {
        return false; // No tiene la sección
      }

      // Verifica si tiene el permiso requerido
      if (section['permissions']) {
        const hasPermission = section['permissions'].some(
          (permission: any) => permission.id === permissionId
        );

        return hasPermission;
      } else {
        return false;
      }
    }

    return false;
  }

  /**
   * Comprobar permisos del usuario actual en la sección correspondiente
   */
  checkSectionPermissions(
    user: any,
    sectionId: number,
    permissionIds: number[]
  ): boolean {
    // Busca la sección por ID
    if (user['sections']) {
      const section = user['sections'].find(
        (section: any) => section.id === sectionId
      );

      if (!section) {
        return false; // No tiene la sección
      }

      if (section['permissions']) {
        // Verifica si tiene todos los permisos requeridos
        const hasAllPermissions = permissionIds.every((id: number) =>
          section['permissions'].some((permission: any) => permission.id === id)
        );

        return hasAllPermissions;
      } else {
        return false;
      }
    }

    return false;
  }

  /**
   * Comprobar permisos de un usuario en un indicador concreto
   */
  checkIndicatorPermission(
    indicator: any,
    user: any,
    permissionId: number
  ): boolean {
    // Verifica si tiene el permiso requerido
    if (indicator['permissions']) {
      const hasPermission = indicator['permissions'].some(
        (permission: any) =>
          permission.permission_id === permissionId &&
          permission.user_id === user.id
      );
      return hasPermission;
    }
    return false;
  }

  /**
   * Comprobar permisos de un usuario en una acción concreta
   */
  checkActionPermission(action: any, user: any, permissionId: number) {
    if (action['permissions']) {
      const hasPermission = action['permissions'].some(
        (permission: any) =>
          permission.permission_id === permissionId &&
          permission.user_id === user.id
      );
      return hasPermission;
    }
    return false;
  }
}
