import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  // Subject que emitirá eventos con un nombre y datos opcionales
  private eventsSource = new Subject<{ event: string; data?: any }>();

  // Hacemos el Subject observable para que los componentes se puedan suscribir a los eventos
  events$ = this.eventsSource.asObservable();

  /**
   * Método para emitir eventos
   * @param event
   * @param data
   */
  emitEvent(event: string, data?: any) {
    this.eventsSource.next({ event, data });
  }
}
