import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { Context } from 'src/app/models/context';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-organization-context',
  templateUrl: './organization-context.page.html',
  styleUrls: ['./organization-context.page.scss'],
})
export class OrganizationContextPage implements OnInit {
  @ViewChild('dt') table: Table;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('content') content: ElementRef;
  loading: boolean = true;
  loadingForm: boolean = false;
  contexts: Context[] = [];
  companyId: string | null;
  forbidden: boolean = false;
  dialogContext: boolean = false;
  selectedContext: Context | null;
  edit: boolean = false;
  form: FormGroup;
  uploadedFile: File | null;
  uploadedFileString: string;
  user: any;
  userByCompany: any;

  constructor(
    private serverService: ServerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private permissionService: PermissionsService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.companyId = sessionStorage.getItem('companyId');
  }

  async ngOnInit() {
    this.form = this.fb.group({
      company_id: [''],
      size: ['', Validators.required],
      structure: ['', Validators.required],
      locations: ['', Validators.required],
      sectors: ['', Validators.required],
      nature: ['', Validators.required],
      scale: ['', Validators.required],
      complexity: ['', Validators.required],
      members: ['', Validators.required],
      relations: ['', Validators.required],
      obligations: ['', Validators.required],
      comments: ['', Validators.required],
      boards: ['', Validators.required],
      date: ['', Validators.required],
      document: [''],
      document_hash: [''],
    });
    await this.getUserByCompany();
    this.getOrganizationContexts();
  }

  /**
   * Obtener los contextos de la organización
   */
  getOrganizationContexts() {
    this.serverService.getData('/api/contexts').subscribe({
      next: (response) => {
        if (response.data) {
          this.contexts = response.data;
        } else {
          this.contexts = [];
        }
        this.loading = false;
      },
      error: (err) => {
        console.error('Error al obtener los contextos: ', err);
        if (err.status === 403) {
          // Error de permisos
          this.forbidden = true;
        }
        this.loading = false;
      },
    });
  }

  /**
   * Manejador de modal de edición de registro de contexto de organización
   */
  showContextDialog(context: Context) {
    if (context) {
      this.selectedContext = context;
      this.form.patchValue({
        ...context,
        date: new Date(context.date),
      });
      this.uploadedFileString = context.document ? context.document : '';
    }

    this.dialogContext = true;
    this.edit = context ? true : false;
  }

  /**
   * Manejador de cierre de modal de creación/edición de registro de contexto de organización
   */
  handleCloseContextModal() {
    this.selectedContext = null;
    this.form.reset();
    this.uploadedFile = null;
    this.edit = false;
  }

  /**
   * Manejador de carga de archivo seleccionado
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo seleccionado
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Envio de formulario de creación/edición de registro de contexto de organización
   */
  async onSubmitForm() {
    await this.getUserByCompany();

    // Permisos de edición
    const hasEditPermission = this.permissionService.checkSectionPermission(
      this.userByCompany,
      18,
      3
    );
    // Permisos de creación
    const hasCreatePermission = this.permissionService.checkSectionPermission(
      this.userByCompany,
      18,
      2
    );

    // Check a true de permisos de creación o edición
    if (
      (!this.edit && hasCreatePermission) ||
      (this.edit && hasEditPermission)
    ) {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      // Procesar datos del formulario
      const formValue = this.form.value;
      const formData = new FormData();

      this.appendFormData(formData, formValue);
      this.loadingForm = true;

      // Comprobación para guardar nuevo registro o editar un existente
      if (this.edit) {
        this.updateContext(formData);
      } else if (hasCreatePermission) {
        this.saveContext(formData);
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'No tiene permisos para crear un registro en esta sección',
        });
      }
    } else {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'No tiene permisos para realizar esta acción',
      });
    }
  }

  /**
   * Función auxiliar para realizar la inserción de datos en FormData
   * @param formData
   * @param formValue
   */
  appendFormData(formData: FormData, formValue: any) {
    formData.append(
      'company_id',
      formValue.company_id ? formValue.company_id.toString() : ''
    );
    formData.append('size', formValue.size.toString());
    formData.append('structure', formValue.structure.toString());
    formData.append('locations', formValue.locations.toString());
    formData.append('sectors', formValue.sectors.toString());

    if (formValue.date) {
      const selectedDate = this.datePipe.transform(
        formValue.date,
        'yyyy-MM-dd'
      );
      if (selectedDate) {
        formData.append('date', selectedDate);
      }
    }

    formData.append('nature', formValue.nature.toString());
    formData.append('scale', formValue.scale.toString());
    formData.append('complexity', formValue.complexity.toString());
    formData.append('members', formValue.members.toString());
    formData.append('relations', formValue.relations.toString());
    formData.append('obligations', formValue.obligations.toString());
    formData.append('comments', formValue.comments.toString());
    formData.append('boards', formValue.boards.toString());

    if (this.uploadedFile) {
      formData.append('document', this.uploadedFile, this.uploadedFile.name);
    }
  }

  /**
   * Función auxiliar para realizar la actualización de un registro de contexto de organización
   * @param formData
   */
  updateContext(formData: FormData) {
    this.serverService
      .updatePostData(`/api/contexts/${this.selectedContext?.id}`, formData)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail:
                'Registro de contexto de organización actualizado correctamente',
            });
            this.dialogContext = false;
            this.loadingForm = false;
            this.getOrganizationContexts();
          }
        },
        error: (err) => {
          console.error(
            'Error al actualizar el registro de contexto de organización:',
            err
          );
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ocurrió un error al actualizar el registro de contexto de organización, inténtelo de nuevo',
          });
          this.loadingForm = false;
        },
      });
  }

  /**
   * Función auxiliar para realizar la inserción de un nuevo registro de contexto de organización
   * @param formData
   */
  saveContext(formData: FormData) {
    this.serverService.sendData(`/api/contexts`, formData).subscribe({
      next: (response) => {
        if (response.data) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail:
              'Registro de contexto de organización guardado correctamente',
          });
          this.dialogContext = false;
          this.loadingForm = false;
          this.getOrganizationContexts();
        }
      },
      error: (err) => {
        console.error(
          'Error al guardar el registro de contexto de organización:',
          err
        );
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail:
            'Ocurrió un error en el registro de contexto de organización, inténtelo de nuevo',
        });
        this.loadingForm = false;
      },
    });
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await lastValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compañías de usuario:',
        err
      );
    } finally {
    }
  }

  /**
   * Manejador de eliminación de eliminación de registro de contexto de organización
   */
  showDeleteContextDialog(event, context: Context) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService.deleteData(`/api/contexts/${context.id}`).subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Registro eliminado correctamente',
              });
              this.getOrganizationContexts();
            }
          },
          error: (error) => {
            console.error('Error eliminando el registro', error);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el registro de contexto de organización, inténtelo de nuevo',
            });
          },
        });
      },
    });
  }

  /**
   * Abrir documento existente de contexto de organización
   */
  openDocument() {
    window.open(this.selectedContext?.document, '_blank');
  }
}
