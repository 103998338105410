import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UsersAssociatesPageRoutingModule } from './users-associates-routing.module';
import { MessageModule } from 'primeng/message';
import { UsersAssociatesPage } from './users-associates.page';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ConfirmDialogModule,
    MessageModule,
    ReactiveFormsModule,
    ToastModule,
    InputTextModule,
    DialogModule,
    InputTextareaModule,
    PasswordModule,
    TableModule,
    MultiSelectModule,
    ConfirmPopupModule,
    SelectButtonModule,
    UsersAssociatesPageRoutingModule,
  ],
  declarations: [UsersAssociatesPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ConfirmationService, MessageService, DatePipe],
})
export class UsersAssociatesPageModule {}
